import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Link } from "react-router-dom";
import { confirmRSVP } from "../api/users";
import Header from "../components/header";
import Section from "../components/section";
import HeadingTitle from "../components/headingTitle";

import Logo from "../assets/images/logo_l.png";

import Heart from "../assets/images/ico-heart2.png";

import { ReactComponent as ArrowRight } from ".././assets/svg/arrow-right.svg";

const Home = () => {
  const [values, setValues] = useState({
    name: "",
    phone: "",
    message: "",
    drink: "",
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(""); // 'success' or 'error'

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (name === "phone") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setValues({
        ...values,
        [name]: numericValue,
      });
    } else if (type === "radio") {
      setValues({
        ...values,
        drink: value,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let formErrors = {};

    if (!values.name) {
      formErrors.name = "Name is required";
    }

    if (!values.phone) {
      formErrors.phone = "Phone number is required";
    }

    if (!values.drink) {
      formErrors.drink = "Drink preference is required";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      // Clear errors
      setErrors({});
      confirmRSVP(values)
        .then((res) => {
          setMessage(res.data.message);
          setMessageType("success");
          setValues({
            name: "",
            phone: "",
            message: "",
            drink: "",
          });
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setMessage("This number has already RSVP'd");
            setMessageType("error");
            setValues({
              name: "",
              phone: "",
              message: "",
              drink: "",
            });
          } else {
            setMessage("Error submitting form. Please try again.");
            setMessageType("error");
          }
          console.error("Error submitting details:", error);
        });
    }
  };

  return (
    <section className="home__rsvp-hero-section ">
      <div className="home__overlay"></div>
      <div className="container">
        <div className="home__boxed-wrapper">
          <Header/>
          <div className="home__boxed-content">
            <h2 className="home__title1-border">Rsvp</h2>
            <div className="home__rsvp-row">
              <div className="home__rsvp-col-left">
                <h3 className="home__rsvp-title4">Please fll our RSVP Form</h3>
                <p>
                  Kindly respond by December 28, 2024. We look forward to
                  celebrating with you!
                </p>
              </div>
              <div className="home__rsvp-col-right">
                <form onSubmit={handleSubmit}>
                  <div className="u-margin-top-small">
            
                    <div className="form__group">
                      <label className="form__label">Full name</label>
                      <input
                        className="form__input"
                        type="text"
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    {errors.name && <p className="error">{errors.name}</p>}
                  </div>
                  <div className="u-margin-top-small">
                    <div className="form__group">
                      <label className="form__label">Phone Number</label>
                      <input
                        className="form__input"
                        type="text"
                        id="phone"
                        name="phone"
                        value={values.phone}
                        onChange={handleInputChange}
                        pattern="[0-9]*"
                        inputMode="numeric"
                      />
                    </div>
                    {errors.phone && <p className="error">{errors.phone}</p>}
                  </div>
                  <div className="u-margin-top-small">
                    <div className="form__group">
                      <label className="form__label">
                        Select Drink preference
                      </label>
                    </div>




                    <div className="home__checkbox-wrapper">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="soft-drinks"
                          name="drink"
                          value="Soft drinks"
                          checked={values.drink === "Soft drinks"}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="soft-drinks"></label>
                      </div>
                      <span>Soft drinks</span>
                    </div>
                    <div className="home__checkbox-wrapper">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="fruit-juice"
                          name="drink"
                          value="100% fruit juice"
                          checked={values.drink === "100% fruit juice"}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="fruit-juice"></label>
                      </div>
                      <span>100% fruit juice</span>
                    </div>
                    <div className="home__checkbox-wrapper">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="cider"
                          name="drink"
                          value="Cider"
                          checked={values.drink === "Cider"}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="cider"></label>
                      </div>
                      <span>Cider</span>
                    </div>
                    <div className="home__checkbox-wrapper">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="dry-red-wine"
                          name="drink"
                          value="Dry Red wine"
                          checked={values.drink === "Dry Red wine"}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="dry-red-wine"></label>
                      </div>
                      <span>Dry Red wine</span>
                    </div>
                    <div className="home__checkbox-wrapper">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="dry-white-wine"
                          name="drink"
                          value="Dry white wine"
                          checked={values.drink === "Dry white wine"}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="dry-white-wine"></label>
                      </div>
                      <span>Dry white wine</span>
                    </div>
                    <div className="home__checkbox-wrapper">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="sweet-wine-rose"
                          name="drink"
                          value="Sweet Wine/Rose"
                          checked={values.drink === "Sweet Wine/Rose"}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="sweet-wine-rose"></label>
                      </div>
                      <span>Sweet Wine/Rose</span>
                    </div>
                    <div className="home__checkbox-wrapper">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="whisky"
                          name="drink"
                          value="Whisky"
                          checked={values.drink === "Whisky"}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="whisky"></label>
                      </div>
                      <span>Whisky</span>
                    </div>
                    <div className="home__checkbox-wrapper">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="gin"
                          name="drink"
                          value="Gin"
                          checked={values.drink === "Gin"}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="gin"></label>
                      </div>
                      <span>Gin</span>
                    </div>
                    <div className="home__checkbox-wrapper">
                      <div className="custom-checkbox">
                        <input
                          type="checkbox"
                          id="cognac"
                          name="drink"
                          value="Cognac"
                          checked={values.drink === "Cognac"}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="cognac"></label>
                      </div>
                      <span>Cognac</span>
                    </div>






                    {errors.drink && <p className="error">{errors.drink}</p>}
                  </div>
                  <div className="u-margin-top-small">
                    <div className="form__group">
                      <label className="form__label">Message</label>
                      <textarea
                        className="form__textarea"
                        id="message"
                        name="message"
                        value={values.message}
                        onChange={handleInputChange}
                        rows="4" 
                      />
                    </div>
                  </div>
                  <div className="u-margin-top-medium">
                    <button type="submit" className="home__send-btn">
                      send
                    </button>
                  </div>
                  {message && (
                    <p className={`message ${messageType}`}>{message}</p>
                  )}
                </form>
              </div>
            </div>
          </div>
          <div className="home__boxed-footer">
            <p className="home__footer-icon">
              <img src={Heart} alt="IMG" />
            </p>
            <p className="home__quote-content">
              "When i saw you i fell in love, and you smiled because you knew."
            </p>
            <p className="home__quote-author">Willian Shakespeare</p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Home;
