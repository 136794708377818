import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  NavLink,
  Link,
} from "react-router-dom";
import Logo from "../assets/images/logo_l.png";

import EVENT01 from "../assets/images/event_00.webp";

import { ReactComponent as Location } from "../assets/svg/icons8-location.svg";
import { ReactComponent as Phone } from "../assets/svg/icons8-mobile-phone.svg";
import { ReactComponent as Menu } from "../assets/svg/icons8-menu.svg";
import { ReactComponent as Close } from "../assets/svg/icons8-close.svg";
// import { ReactComponent as Logo } from ".././assets/images/33.svg";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Set isScrolled to true if page is scrolled more than 50px, otherwise false
      setIsScrolled(window.scrollY > 50);
    };

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className="header__mobile">
        <div className="container">
          <div className="header__mobile-container">
            <div className="header__logo-box">
              <img src={Logo} alt="33" />
            </div>

            <div className="header__mobile-menu" onClick={toggleMenu}>
              <Menu />
            </div>

            <div
              className={
                isOpen
                  ? "header__mobile-nav header__mobile-nav-open"
                  : "header__mobile-nav"
              }
            >
              <div className="header__mobile-close" onClick={toggleMenu}>
                <Close />
              </div>
              <ul className="header__mobile-list">
                <li className="header__mobile-item">
                  <Link to={`/`} className="home__nav-link">
                    <span className="home__nav-link-title home__mobile-nav-title">About</span>
                    <span className="home__nav-link-subtitle">
                      wedding details
                    </span>
                  </Link>
                </li>
                <li className="header__mobile-item">
                  <Link to={`/ceremony`} className="home__nav-link">
                    <span className="home__nav-link-title home__mobile-nav-title">Ceremony</span>
                    <span className="home__nav-link-subtitle">
                      all information
                    </span>
                  </Link>
                </li>
                <li className="header__mobile-item">
                  <Link to={`/rsvp`} className="home__nav-link home__mobile-nav-link">
                    <span className="home__nav-link-title home__mobile-nav-title">RSVP</span>
                    <span className="home__nav-link-subtitle">
                      Are you attending
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            {isOpen && (
              <div
                className="header__mobile-overlay"
                onClick={toggleMenu}
              ></div>
            )}
          </div>
        </div>
      </div>

      <div className="home__boxed-header">
        <Link to={`/`} className="home__boxed-header-logo">
          <img src={Logo} alt="IMG" />
        </Link>
        <div className="home__boxed-nav">
          <ul className="home__nav-list">
            <li className="home__nav-item">
              <Link to={`/`} className="home__nav-link">
                <span className="home__nav-link-title">About</span>
                <span className="home__nav-link-subtitle">wedding details</span>
              </Link>
            </li>
            <li className="home__nav-item">
              <Link to={`/ceremony`} className="home__nav-link">
                <span className="home__nav-link-title">Ceremony</span>
                <span className="home__nav-link-subtitle">all information</span>
              </Link>
            </li>
            <li className="home__nav-item">
              <Link to={`/rsvp`} className="home__nav-link">
                <span className="home__nav-link-title">RSVP</span>
                <span className="home__nav-link-subtitle">
                  Are you attending
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
