import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./scrollToTop";


import Home from "./pages/Home";
import Rsvp from "./pages/rsvp";
import Ceremony from "./pages/ceremony";


const Routing = () => {
  // ScrollToTop();

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/rsvp" exact element={<Rsvp />} />
        <Route path="/ceremony" exact element={<Ceremony />} />

      </Routes>
    </Router>
  );
};
export default Routing;
