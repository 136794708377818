import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Link } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Section from "../components/section";
import HeadingTitle from "../components/headingTitle";

import Logo from "../assets/images/logo_l.png";
import Logo2 from "../assets/images/logo_p.png";
import Heart from "../assets/images/ico-heart2.png";

import { ReactComponent as ArrowRight } from ".././assets/svg/arrow-right.svg";

const Home = () => {
  const targetDate = new Date("2024-12-28T00:00:00").getTime();
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference <= 0) {
        clearInterval(timer);
        return;
      }

      const timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };

      setTimeLeft(timeLeft);
    };

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <section className="home__hero-section">
      <div className="home__overlay"></div>
      <div className="container">
        <div className="home__boxed-wrapper">
          <Header/>
          <div className="home__boxed-content">
            <div className="home__boxed-content-logo">
              <img src={Logo2} alt="IMG" />
            </div>
            <div className="home__boxed-content-countdown">
              <div className="home__boxed-content-heading">
                <div className="home__boxed-content-heart">
                  <img src={Heart} alt="IMG" />
                </div>
                <h2 className="home__countdown-title ">
                  <span className="home__countdown-title-borders">
                    Saturday 28 December 2024 at St Mary’s Cathedral Basilica,
                    Bulawayo
                  </span>
                </h2>
                <div className="home__boxed-content-heading-text"></div>
              </div>
              <div className="home__boxed-content-count">
                <div className="home__boxed-content-count-item">
                  <div className="home__boxed-contentcountdown-col">
                    <span className="home__boxed-contentcountdown-time">
                      {String(timeLeft.days).padStart(2, "0")}
                    </span>{" "}
                    Days
                  </div>
                </div>
                <div className="home__boxed-content-count-item">
                  <div className="home__boxed-contentcountdown-col">
                    <span className="home__boxed-contentcountdown-time">
                      {String(timeLeft.hours).padStart(2, "0")}
                    </span>{" "}
                    Hours
                  </div>
                </div>
                <div className="home__boxed-content-count-item">
                  <div className="home__boxed-contentcountdown-col">
                    <span className="home__boxed-contentcountdown-time">
                      {String(timeLeft.minutes).padStart(2, "0")}
                    </span>{" "}
                    Minutes
                  </div>
                </div>
                <div className="home__boxed-content-count-item">
                  <div className="home__boxed-contentcountdown-col">
                    <span className="home__boxed-contentcountdown-time">
                      {String(timeLeft.seconds).padStart(2, "0")}
                    </span>{" "}
                    Seconds
                  </div>
                </div>
              </div>
            </div>
            <h3 className="home__countdown-title-bottom ">
              <span className="home__countdown-title-borders">
                Until we get Married!
              </span>
            </h3>
            <h2 className="home__title1-border u-margin-top-medium">Reception </h2>
            <div class="home__page-intro">
              <p>
                PeniQuila Gardens, 83 Hopefountain road Waterford Road Bulawayo
              </p>
            </div>
          </div>
          <div className="home__boxed-footer">
            <p className="home__footer-icon">
              <img src={Heart} alt="IMG" />
            </p>
            <p className="home__quote-content">
              "When i saw you i fell in love, and you smiled because you knew."
            </p>
            <p className="home__quote-author">Willian Shakespeare</p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Home;
