import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Link } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import Section from "../components/section";
import HeadingTitle from "../components/headingTitle";

import Logo from "../assets/images/logo_l.png";
import Logo2 from "../assets/images/logo_p.png";
import Heart from "../assets/images/ico-heart2.png";

import { ReactComponent as ArrowRight } from ".././assets/svg/arrow-right.svg";

const Home = () => {
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      const scrollPosition = window.scrollY;
      sections.forEach((section, index) => {
        if (
          scrollPosition >= index * window.innerHeight &&
          scrollPosition < (index + 1) * window.innerHeight
        ) {
          section.style.opacity = "1";
        } else {
          section.style.opacity = "0.5";
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className="home__ceremony-hero-section">
      <div className="home__overlay"></div>
      <div className="container">
        <div className="home__boxed-wrapper">
          <Header />
          <div className="home__boxed-content">
            <h2 className="home__title1-border">Ceremony</h2>
            <div class="home__page-intro">
              <p>
                Coming to our wedding? Well here are the details on the big day!
                <br /> Don’t forget to <Link to={`/rsvp`}>RSVP</Link>. See you
                there!
              </p>
            </div>

            <div className="wedding-day-schedule__wrapper">
              <div className="wedding-day-schedule__item">
                <p className="wedding-day-schedule__event">
                  ceremony at St Mary’s Cathedral Basilica, Bulawayo
                </p>
                <p className="wedding-day-schedule__time">09:00 AM</p>
              </div>
              <div className="wedding-day-schedule__item">
                <p className="wedding-day-schedule__event">
                  PHOTOS/WELCOME SNACKS/ COCKTAIL HOUR
                </p>
                <p className="wedding-day-schedule__time">11:30 AM</p>
              </div>
              <div className="wedding-day-schedule__item">
                <p className="wedding-day-schedule__event">
                  GWEDDING RECEPTION PROGRAM STARTS (PeniQuila Gardens)
                </p>
                <p className="wedding-day-schedule__time">12:30 PM</p>
              </div>
              <div className="wedding-day-schedule__item">
                <p className="wedding-day-schedule__event">Grand Entrance</p>
                <p className="wedding-day-schedule__time">01:00 PM</p>
              </div>
              <div className="wedding-day-schedule__item">
                <p className="wedding-day-schedule__event">lunch/bar opens</p>
                <p className="wedding-day-schedule__time">01:15 PM</p>
              </div>
              <div className="wedding-day-schedule__item">
                <p className="wedding-day-schedule__event">entertainment</p>
                <p className="wedding-day-schedule__time">02:00 PM</p>
              </div>
              <div className="wedding-day-schedule__item">
                <p className="wedding-day-schedule__event">Family speeches</p>
                <p className="wedding-day-schedule__time">03:00 PM</p>
              </div>
              <div className="wedding-day-schedule__item">
                <p className="wedding-day-schedule__event">gifts</p>
                <p className="wedding-day-schedule__time">03:15 PM</p>
              </div>
              <div className="wedding-day-schedule__item">
                <p className="wedding-day-schedule__event">cake cutting</p>
                <p className="wedding-day-schedule__time">04:00 PM</p>
              </div>
              <div className="wedding-day-schedule__item">
                <p className="wedding-day-schedule__event">
                  Best girl/ best man speeches
                </p>
                <p className="wedding-day-schedule__time">04:45 PM</p>
              </div>
              <div className="wedding-day-schedule__item">
                <p className="wedding-day-schedule__event">Vote of thanks</p>
                <p className="wedding-day-schedule__time">05:15 PM</p>
              </div>
              <div className="wedding-day-schedule__item">
                <p className="wedding-day-schedule__event">
                  End of main program
                </p>
                <p className="wedding-day-schedule__time">06:00 PM</p>
              </div>
            </div>
            <h2 className="home__title1-border">Theme</h2>
            <div class="home__page-intro">
              <p>Shades of blue</p>
            </div>
            <h2 className="home__title1-border u-margin-top-medium">
              Gift registry
            </h2>
            <div class="home__page-intro">
              <p>While gifts are acceptable, cash is preferable</p>
            </div>
            <h2 className="home__title1-border">Housekeeping </h2>
            <div class="home__page-intro">
              <p>
                In as much as we adore your little ones this is an{" "}
                <span>ADULTS ONLY</span> celebration
              </p>
            </div>
            <h2 className="home__title1-border u-margin-top-medium">
              Questions
            </h2>
            <div class="home__page-intro">
              <p>Vikani Family: Mrs K Vikani +263 77 162 7953</p>
              <br />
              <p>Ncube Family: Mrs S Dzarova +263 77 297 5428 </p>
            </div>
          </div>
          <div className="home__boxed-footer">
            <p className="home__footer-icon">
              <img src={Heart} alt="IMG" />
            </p>
            <p className="home__quote-content">
              "When i saw you i fell in love, and you smiled because you knew."
            </p>
            <p className="home__quote-author">Willian Shakespeare</p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Home;
